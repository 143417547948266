import { styled } from 'react-ui-kit-exante';

export const StyledViewContainer = styled('div')`
  width: calc(100% - 232px);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledContent = styled('div')`
  margin: 0 16px;
`;
