import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, omit } from 'lodash';
import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TChildren } from 'types/TChildren';
import { useEditSecurityMutation, useGetPermissionsQuery } from '~/api';
import { TParams } from '~/router/router.types';
import { formatDate } from '~/utils/formatters/formatDate';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import {
  TWithdrawalSecurityFormContext,
  TWithdrawalSecurityFormProps,
} from './WithdrawalSecurityFormContext.types';
import {
  getDefaultFormValues,
  getWithdrawalSecurityFormValidationScheme,
} from './helpers';

const initialState: TWithdrawalSecurityFormContext = {
  isEdit: false,
  resetForm: () => {},
  setIsEdit: () => {},
  formNotEdited: true,
  isCanChange: false,
  isLoading: false,
  withdrawalSecurity: null,
};

export const WithdrawalSecurityFormContext =
  createContext<TWithdrawalSecurityFormContext>(initialState);

export const WithdrawalSecurityFormProvider: FC<
  TWithdrawalSecurityFormProps & TChildren
> = ({ children, withdrawalSecurity }) => {
  const { id: withdrawalId } = useParams<TParams>();
  const [onEditWithdrawalSecurity, state] = useEditSecurityMutation();
  const [isEdit, setIsEdit] = useState(initialState.isEdit);
  const { data: permissions } = useGetPermissionsQuery();

  const isCanChange = Boolean(
    permissions?.withdrawals.withdrawals_security_withdraw,
  );

  const defaultValues = getDefaultFormValues(withdrawalSecurity, isCanChange);
  const validationScheme = getWithdrawalSecurityFormValidationScheme();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const { handleSubmit, getValues, formState, watch, reset } = methods;

  watch(Object.entries(defaultValues).map(([name]) => name));

  const formData = getDirtyValues(formState.dirtyFields, getValues());

  useEffect(() => {
    reset(defaultValues);
  }, [isEdit]);

  const onSave = () => {
    if (withdrawalId) {
      setIsEdit(!isEdit);

      const data = {
        ...omit(withdrawalSecurity, 'id'),
        ...formData,
        settlement_date: formatDate(formData.settlement_date as string),
        trade_date: formatDate(formData.trade_date as string),
      };

      onEditWithdrawalSecurity({
        withdrawalId,
        data,
      });

      methods.reset(defaultValues);
    }
  };

  const value = useMemo(
    (): TWithdrawalSecurityFormContext => ({
      isEdit,
      setIsEdit,
      isCanChange,
      formNotEdited: isEmpty(formData),
      resetForm: () => methods.reset(defaultValues),
      withdrawalSecurity,
      isLoading: state.isLoading,
    }),
    [
      onSave,
      formData,
      isEdit,
      isCanChange,
      setIsEdit,
      withdrawalSecurity,
      state.isLoading,
    ],
  );

  return (
    <WithdrawalSecurityFormContext.Provider value={value}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>{children}</form>
      </FormProvider>
    </WithdrawalSecurityFormContext.Provider>
  );
};
