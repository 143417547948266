import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  css,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';
import { IMenuItemStyledComponentProps } from '../Menu.types';

export const StyledSubList = styled(List, {
  shouldForwardProp: blockNonNativeProps(['opened']),
})`
  padding: 0 40px 0 48px;

  .MenuItemButton {
    padding: 8px 0;
    line-height: 16px;
  }

  .MenuItemTitle {
    font-size: ${({ theme }) => theme.size.text.md};
  }

  .MenuItemWrapper {
    margin-bottom: 0;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  margin: 0;
`;

export const StyledExpandMore = styled(ExpandMore)`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledShowAttentionMark = styled('span')`
  display: inline-block;

  vertical-align: middle;

  width: 8px;
  height: 8px;

  margin-inline-start: 8px;
  margin-block-start: -4px;

  font-size: 0;

  background-color: ${({ theme }) => theme.color.typo.radical};
  border-radius: 50%;
`;

export const StyledExpandLess = styled(ExpandLess)`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: blockNonNativeProps(['opened', 'hasChild']),
})`
  display: block;
  padding: 0;
  margin-bottom: 8px;
`;

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: blockNonNativeProps(['active', 'level']),
})<IMenuItemStyledComponentProps>`
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 14px;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  gap: 8px;
  background: none;
  border-left: 2px solid transparent;
  color: ${({ theme }) => theme.color.typo.action};

  &:hover {
    background: none;
    color: ${({ theme }) => theme.color.typo.promo};
  }

  ${({ theme, active, level }) =>
    active &&
    level === 0 &&
    css`
      border-color: ${theme.color.typo.action};
      color: ${theme.color.typo.primary};
      background-color: ${theme.color.bg.secondary};
      font-weight: 600;

      &:hover {
        background-color: ${theme.color.bg.secondary};
        color: ${theme.color.typo.primary};
      }
    `}

  ${({ theme, active, level }) =>
    active &&
    level &&
    level > 0 &&
    css`
      color: ${theme.color.typo.primary};
      font-weight: 600;

      &:hover {
        color: ${theme.color.typo.primary};
      }
    `}
`;

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: blockNonNativeProps(['active']),
})<IMenuItemStyledComponentProps>`
  min-width: 0;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.typo.secondary};

  // todo ERROR with Component selectors in projects that use ui-kit
  //  Error: Component selectors can only be used in conjunction with @emotion/babel-plugin, the swc Emotion plugin, or another Emotion-aware compiler transform.
  // task https://jira.exante.eu/browse/RUI-262

  .ListItemButton:hover & {
    transform: scale(1.2);
  }

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.color.typo.primary};
    `}
`;
