import { combineReducers } from '@reduxjs/toolkit';

import {
  depositsApi,
  permissionsApi,
  personalSettingsApi,
  bookmarksApi,
  requestsApi,
  dataChangeRequestsApi,
  withdrawalsApi,
  commentsApi,
  globalContextApi,
  contactsApi,
  tagsApi,
  userManagementApi,
  adminChoicesApi,
  applicationLegalEntitiesApi,
  reportsApi,
  notificationsApi,
} from '~/api';

export const rootReducer = combineReducers({
  [depositsApi.reducerPath]: depositsApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [personalSettingsApi.reducerPath]: personalSettingsApi.reducer,
  [bookmarksApi.reducerPath]: bookmarksApi.reducer,
  [requestsApi.reducerPath]: requestsApi.reducer,
  [dataChangeRequestsApi.reducerPath]: dataChangeRequestsApi.reducer,
  [withdrawalsApi.reducerPath]: withdrawalsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [globalContextApi.reducerPath]: globalContextApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [userManagementApi.reducerPath]: userManagementApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [adminChoicesApi.reducerPath]: adminChoicesApi.reducer,
  [applicationLegalEntitiesApi.reducerPath]:
    applicationLegalEntitiesApi.reducer,
});
