import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { TStyledEntryPageContainerProps } from './EntryPage.types';

export const StyledEntryPageContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps('columnsCount'),
})<TStyledEntryPageContainerProps>`
  display: grid;
  grid-template-columns: ${({ columnsCount }) =>
    [...Array(columnsCount)]
      .map(() => `${100 / (columnsCount || 1)}%`)
      .join(' ')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 100%;
  }
`;
