import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useLazyGetHoldReasonsQuery,
  useLazyGetRejectReasonsQuery,
  useWithdrawalActionsMutation,
  useLazyGetWithdrawalExportToXMLQuery,
  useWithdrawalConvertMutation,
} from '~/api';
import { WithdrawalTypesOfActions } from '~/pages/WithdrawalEntry/components/WithdrawalHeader/WithdrawalHeader.constants';
import { TParams } from '~/router/router.types';

export const useWithdrawalActions = () => {
  const { id: withdrawalId } = useParams<TParams>();
  const [reason, setReason] = useState('');
  const [reasonOptions, setReasonOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [holdBtnAnchorEl, setHoldBtnAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [rejectBtnAnchorEl, setRejectBtnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [withdrawFromAccountBtnAnchorEl, setWithdrawFromAccountBtnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [sendFundsToClientBtnAnchorEl, setSendFundsToClientBtnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [convertBtnAnchorEl, setConvertBtnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [rollbackBtnAnchorEl, setRollbackBtnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [activeConfirm, setActiveConfirm] = useState('');

  const [onWithdrawalAction, stateWithdrawalAction] =
    useWithdrawalActionsMutation();
  const [onWithdrawalConvert, stateWithdrawalConvert] =
    useWithdrawalConvertMutation();

  const [getHoldReasons, holdState] = useLazyGetHoldReasonsQuery();
  const [getRejectReason, rejectState] = useLazyGetRejectReasonsQuery();
  const [getWithdrawalExportToXML, stateExportXML] =
    useLazyGetWithdrawalExportToXMLQuery();

  const isReasonsLoading = holdState.isLoading || rejectState.isLoading;

  const onChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const fetchHoldReasons = async () => {
    const { data } = await getHoldReasons();

    if (data) {
      const preparedData = data.map(({ reason_text: reasonText }) => ({
        value: reasonText,
        label: reasonText,
      }));

      setReasonOptions(preparedData);
    }
  };

  const fetchRejectReasons = async () => {
    const { data } = await getRejectReason();

    if (data) {
      const preparedData = data.map(({ reason_text: reasonText }) => ({
        value: reasonText,
        label: reasonText,
      }));

      setReasonOptions(preparedData);
    }
  };

  const openHoldConfirm = (event: React.MouseEvent<HTMLElement>) => {
    fetchHoldReasons();
    setActiveConfirm(WithdrawalTypesOfActions.Hold);
    setHoldBtnAnchorEl(event.currentTarget);
  };

  const openRejectConfirm = (event: React.MouseEvent<HTMLElement>) => {
    fetchRejectReasons();
    setActiveConfirm(WithdrawalTypesOfActions.Reject);
    setRejectBtnAnchorEl(event.currentTarget);
  };

  const openWithdrawFromAccountConfirm = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setActiveConfirm(WithdrawalTypesOfActions.Withdraw);
    setWithdrawFromAccountBtnAnchorEl(event.currentTarget);
  };

  const openSendFundsToClientConfirm = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setActiveConfirm(WithdrawalTypesOfActions.WithdrawWire);
    setSendFundsToClientBtnAnchorEl(event.currentTarget);
  };

  const openConvertConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setActiveConfirm(WithdrawalTypesOfActions.Convert);
    setConvertBtnAnchorEl(event.currentTarget);
  };

  const openRollbackConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setActiveConfirm(WithdrawalTypesOfActions.Rollback);
    setRollbackBtnAnchorEl(event.currentTarget);
  };

  const handleHoldClose = () => {
    setHoldBtnAnchorEl(null);
    setActiveConfirm('');
    setReason('');
    setReasonOptions([]);
  };

  const handleRejectClose = () => {
    setRejectBtnAnchorEl(null);
    setActiveConfirm('');
    setReason('');
  };

  const handleWithdrawFromAccountClose = () => {
    setWithdrawFromAccountBtnAnchorEl(null);
    setActiveConfirm('');
  };

  const handleSendFundsToClientClose = () => {
    setSendFundsToClientBtnAnchorEl(null);
    setActiveConfirm('');
  };

  const handleConvertClose = () => {
    setActiveConfirm('');
    setConvertBtnAnchorEl(null);
  };

  const handleRollbackClose = () => {
    setActiveConfirm('');
    setRollbackBtnAnchorEl(null);
  };

  const handleOnHold = () => {
    if (withdrawalId && reason) {
      handleHoldClose();
      onWithdrawalAction({
        id: withdrawalId,
        content: reason,
        type: WithdrawalTypesOfActions.Hold,
      });
    }
  };

  const handleReject = () => {
    if (withdrawalId && reason) {
      handleRejectClose();
      onWithdrawalAction({
        id: withdrawalId,
        content: reason,
        type: WithdrawalTypesOfActions.Reject,
      });
    }
  };

  const handleWithdraw = () => {
    if (withdrawalId) {
      handleWithdrawFromAccountClose();
      onWithdrawalAction({
        id: withdrawalId,
        type: WithdrawalTypesOfActions.Withdraw,
      });
    }
  };

  const handleMakeActive = () => {
    if (withdrawalId) {
      onWithdrawalAction({
        id: withdrawalId,
        type: WithdrawalTypesOfActions.MakeActive,
      });
    }
  };

  const handleSendFundsToClient = () => {
    if (withdrawalId) {
      handleSendFundsToClientClose();
      onWithdrawalAction({
        id: withdrawalId,
        type: WithdrawalTypesOfActions.WithdrawWire,
      });
    }
  };

  const handleExportToXML = () => {
    if (withdrawalId) {
      getWithdrawalExportToXML({
        id: withdrawalId,
      });
    }
  };

  const handleConvert = () => {
    if (withdrawalId) {
      handleConvertClose();
      onWithdrawalConvert({ id: withdrawalId });
    }
  };

  const handleRollback = () => {
    if (withdrawalId) {
      handleRollbackClose();
      onWithdrawalAction({
        id: withdrawalId,
        type: WithdrawalTypesOfActions.Rollback,
      });
    }
  };

  const onHoldMethods = {
    handleOnHold,
    openHoldConfirm,
    holdBtnAnchorEl,
    handleHoldClose,
  };

  const rejectMethods = {
    rejectBtnAnchorEl,
    handleReject,
    handleRejectClose,
    openRejectConfirm,
  };
  const withdrawMethods = {
    withdrawFromAccountBtnAnchorEl,
    handleWithdraw,
    handleWithdrawFromAccountClose,
    openWithdrawFromAccountConfirm,
  };
  const makeActiveMethods = {
    handleMakeActive,
  };
  const sendFundsToClientMethods = {
    sendFundsToClientBtnAnchorEl,
    handleSendFundsToClient,
    handleSendFundsToClientClose,
    openSendFundsToClientConfirm,
  };
  const exportToXMLBtnMethods = {
    handleExportToXML,
  };
  const convertMethods = {
    convertBtnAnchorEl,
    handleConvert,
    handleConvertClose,
    openConvertConfirm,
  };

  const rollbackMethods = {
    rollbackBtnAnchorEl,
    handleRollback,
    handleRollbackClose,
    openRollbackConfirm,
  };

  return {
    reason,
    activeConfirm,
    reasonOptions,
    onChangeSelect,
    isReasonsLoading,
    onHoldMethods,
    rejectMethods,
    withdrawMethods,
    makeActiveMethods,
    sendFundsToClientMethods,
    exportToXMLBtnMethods,
    convertMethods,
    rollbackMethods,
    isLoading:
      stateWithdrawalAction.isLoading ||
      stateExportXML.isLoading ||
      stateWithdrawalConvert.isLoading,
  };
};
