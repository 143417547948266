export const DEFAULT_EMPTY_PLACEHOLDER = '–';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DEFAULT_CURRENCY = 'EUR';

export enum FieldTypes {
  Select = 'select',
  Autocomplete = 'autocomplete',
  Checkbox = 'checkbox',
  DateField = 'date',
  DateTimeField = 'datetime',
  NumberField = 'number',
  Input = 'input',
  Textarea = 'textarea',
  File = 'file',
}

export const CHECK_ONLY_NUMBER = /^\d+$/;
