import { isArray } from 'lodash';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { AutocompleteAsync } from 'react-ui-kit-exante';

import {
  TFormSelectAsyncContainerProps,
  TValue,
} from './FormMultiSelectAsyncContainer.types';

export const FormMultiSelectAsyncContainer = ({
  autoSelect = false,
  disableCloseOnSelect = true,
  disabled = false,
  fetchData,
  freeSolo = false,
  fullWidth = true,
  label = '',
  name,
  onChange: onChangeHandler,
  isMultiple,
  size,
  sx = {},
  noOptionsText,
  clearable = false,
}: TFormSelectAsyncContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const transformToValues = (newValues: TValue[] | TValue) => {
    if (isArray(newValues)) {
      return newValues?.map((newValue) => {
        if (typeof newValue === 'string' || typeof newValue === 'number') {
          return newValue;
        }
        return newValue?.value;
      });
    }

    if (typeof newValues === 'string' || typeof newValues === 'number') {
      return newValues;
    }
    return newValues?.value;
  };

  const multiSelectChangeHandler = useCallback(
    (event: SyntheticEvent, newValues: TValue[] | TValue) => {
      onChange(transformToValues(newValues));

      if (onChangeHandler) {
        onChangeHandler(transformToValues(newValues));
      }

      event.preventDefault();
    },
    [onChange, onChangeHandler],
  );

  const controlProps = useMemo(() => ({ error: Boolean(error) }), [error]);

  return (
    <AutocompleteAsync
      disableClearable={!clearable}
      autoSelect={autoSelect}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      error={!!error}
      fetchData={fetchData}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      isMultiple={isMultiple}
      message={error?.message}
      onChange={multiSelectChangeHandler}
      options={[]}
      placeholder={label}
      value={value}
      controlProps={controlProps}
      size={size}
      sx={sx}
      noOptionsText={noOptionsText}
    />
  );
};
