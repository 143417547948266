import { IconButton, styled } from 'react-ui-kit-exante';

export const StyledEntryScreenHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 28px 24px;
`;

export const StyledEntryScreenHeaderActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledEntryScreenHeaderTitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledEntryScreenHeaderTitleWIthInfo = styled('div')`
  width: 50%;
`;

export const StyledEntryScreenHeaderFunds = styled('div')`
  border-radius: 32px;
  font-weight: 400;
  font-size: 16px;
  padding: 4px 16px;
  width: fit-content;
  margin-top: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.color.bg.primary};
  font-family: ${({ theme }) => theme?.font?.header};
  background-color: ${({ theme }) => theme?.color?.typo?.action};
`;

export const StyledEntryScreenHeaderTitle = styled('div')`
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  margin: 0 8px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledEntryScreenHeaderBlockIcon = styled(IconButton)`
  margin-left: 5px;
`;
