import { FileUpload, styled } from 'react-ui-kit-exante';

export const StyledImportContactsTitle = styled('h2')`
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: 400;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledImportContactsParagraph = styled('p')`
  margin: 0 0 8px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.secondary};
`;

export const StyledImportContactsErrors = styled('ul')`
  margin: 0;
  padding: 0;
  font-size: 13px;
  list-style: none;
  color: ${({ theme }) => theme?.color.typo.secondary};

  li {
    margin-top: 24px;
  }

  strong {
    display: block;
    margin-bottom: 8px;
    color: ${({ theme }) => theme?.color.typo.radical};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StyledImportContactsFileUpload = styled(FileUpload)`
  margin: 24px 0;
`;

export const StyledImportContactsProgress = styled('p')`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.secondary};

  .loader {
    margin-right: 8px;

    & > div {
      position: relative;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const StyledImportContactsWarning = styled('p')`
  margin: 0 0 8px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.radical};
`;
