import { FormLayout, styled } from 'react-ui-kit-exante';

export const StyledImportTagForm = styled('form')``;

export const StyledImportTagFormLayout = styled(FormLayout)`
  margin-bottom: 16px;
`;

export const StyledImportTagParagraph = styled('p')`
  margin: 0 0 16px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.secondary};
`;

export const StyledImportTagActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
`;

export const StyledImportTagProgress = styled('p')`
  display: flex;
  align-items: center;
  margin: 16px 0 8px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.secondary};

  .loader {
    margin-right: 8px;

    & > div {
      position: relative;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const StyledImportTagWarning = styled('p')`
  margin: 16px 0 8px;
  font-size: 13px;
  color: ${({ theme }) => theme?.color.typo.radical};
`;

export const StyledImportTagErrors = styled('ul')`
  margin: 0;
  padding: 0;
  font-size: 13px;
  list-style: none;
  color: ${({ theme }) => theme?.color.typo.secondary};

  li {
    margin-top: 24px;
  }

  strong {
    display: block;
    margin-bottom: 8px;
    color: ${({ theme }) => theme?.color.typo.radical};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
`;
