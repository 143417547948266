import { apiRequest } from './apiRequest';
import { check403Error, handleError } from './helpers';
import { TBaseQueryHandlerArguments } from './types';

export async function baseQueryHandler({
  method = 'GET',
  requestOptions,
  onError,
  onSuccess,
  ignoreForbiddenError,
  ...rest
}: TBaseQueryHandlerArguments) {
  try {
    const response = await apiRequest(
      {
        method,
        ...rest,
      },
      requestOptions,
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    return {
      data: response.data,
      meta: rest,
    };
  } catch (error) {
    if (ignoreForbiddenError && check403Error(error)) {
      return {
        data: null,
        meta: rest,
      };
    }

    if (onError && error instanceof Error) {
      onError(error);
    }

    return {
      error: handleError(error),
    };
  }
}
