import { Loader } from 'react-ui-kit-exante';

import { styled } from '~/theme';

export const StyledRequestEntryBtnLoader = styled(Loader)`
  display: flex;
  align-items: center;
  margin: 0 31px;
`;

export const StyledRequestEntryErrorPage = styled('h4')`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

export const StyledRequestEntryViewLink = styled('a')`
  color: ${({ theme }) => theme?.color.typo.action};

  &:hover,
  &:visited {
    color: ${({ theme }) => theme?.color.typo.promo};
  }
`;
