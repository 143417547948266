import { FC, useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
} from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import { PermissionsTables } from 'pages/PermissionsTables';
import {
  Admin,
  Support,
  Onboarding,
  SalesQA,
  Backoffice,
  Marketing,
} from 'pages/UserManagementPages';
import { PATHS, ROOT_BASEURL } from 'router';
import { getBaseName } from 'utils/isBranchStand';
import {
  useGetBookmarksQuery,
  useGetPermissionsQuery,
  useGetPersonalSettingsQuery,
} from '~/api';
import { useGetGlobalContextQuery } from '~/api/globalContext';
import { ContactsContainer } from '~/pages/Contacts';
import { ContactsImportEntry } from '~/pages/ContactsImportEntry';
import { DataChangeRequests } from '~/pages/DataChangeRequests';
import { DataChangeRequestsEntry } from '~/pages/DataChangeRequestsEntry';
import { DepositEntry } from '~/pages/DepositEntry';
import { ImportTag } from '~/pages/ImportTag';
import { Notifications } from '~/pages/Notifications';
import { Reports } from '~/pages/Reports';
import { RequestEntryContainer } from '~/pages/RequestEntry';
import { Requests } from '~/pages/Requests';
import { WithdrawalEntry } from '~/pages/WithdrawalEntry';
import { WithdrawalsContainer } from '~/pages/Withdrawals';

import { DepositsContainer } from '../../pages/Deposits/Deposits';

import { StyledLoaderContainer } from './ApplicationContainer.styled';
import { Layout } from './components';
import { Navigation } from './components/Navigation';
import { ViewContainer } from './components/ViewContainer';

import './index.css';

export const ApplicationContainer: FC = () => {
  const history = window.runUIhistoryContainer;

  const permissions = useGetPermissionsQuery();
  const personalSettings = useGetPersonalSettingsQuery();
  const bookmarks = useGetBookmarksQuery();
  const globalContext = useGetGlobalContextQuery();

  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  const AppRoutes = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path={PATHS.DEPOSITS} element={<DepositsContainer />} />
        <Route path={PATHS.DEPOSIT_ENTRY} element={<DepositEntry />} />
        <Route
          path={PATHS.DATA_CHANGE_REQUESTS}
          element={<DataChangeRequests />}
        />
        <Route
          path={PATHS.DATA_CHANGE_REQUESTS_ENTRY}
          element={<DataChangeRequestsEntry />}
        />
        <Route path={PATHS.MONITORING} element={<Requests />} />
        <Route
          path={PATHS.MONITORING_ENTRY}
          element={<RequestEntryContainer />}
        />
        <Route path={PATHS.WITHDRAWALS} element={<WithdrawalsContainer />} />
        <Route path={PATHS.WITHDRAWALS_ENTRY} element={<WithdrawalEntry />} />
        <Route
          path={window.runUIhistoryContainer ? `${ROOT_BASEURL}/*` : '*'}
          element={<Navigate to={PATHS.DEPOSITS} replace />}
        />
        <Route path={PATHS.CONTACTS} element={<ContactsContainer />} />
        <Route
          path={PATHS.CONTACTS_IMPORT_CONTACT}
          element={<ContactsImportEntry />}
        />
        <Route path={PATHS.IMPORT_TAG} element={<ImportTag />} />
        <Route path={PATHS.ADMIN} element={<Admin />} />
        <Route path={PATHS.SUPPORT} element={<Support />} />
        <Route path={PATHS.ONBOARDING} element={<Onboarding />} />
        <Route path={PATHS.SALES_QA} element={<SalesQA />} />
        <Route path={PATHS.BACKOFFICE} element={<Backoffice />} />
        <Route path={PATHS.MARKETING} element={<Marketing />} />
        <Route path={PATHS.ABOUT_PERMISSIONS} element={<PermissionsTables />} />
        <Route path={PATHS.REPORTS} element={<Reports />} />
        <Route path={PATHS.NOTIFICATIONS} element={<Notifications />} />
      </Routes>
    </ErrorBoundary>
  );

  const isLoading =
    permissions?.isLoading ||
    personalSettings?.isLoading ||
    bookmarks?.isLoading ||
    globalContext?.isLoading;

  const isError =
    permissions?.isError ||
    personalSettings?.isError ||
    bookmarks?.isError ||
    globalContext?.isError;

  if (isLoading) {
    return (
      <StyledLoaderContainer className="LoaderContainer">
        <Loader size="l" isCentered isInner />
      </StyledLoaderContainer>
    );
  }

  // todo redirect to error page or Notification
  if (isError) {
    return <span>Something went wrong</span>;
  }

  return window.runUIhistoryContainer ? (
    <Router
      location={navigator.location}
      navigationType={navigator.action}
      navigator={history}
    >
      {AppRoutes}
    </Router>
  ) : (
    <BrowserRouter basename={getBaseName()}>
      <Layout>
        <Navigation />
        <ViewContainer>{AppRoutes}</ViewContainer>
      </Layout>
    </BrowserRouter>
  );
};
