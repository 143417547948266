import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox } from 'react-ui-kit-exante';

import { useGetPermissionsQuery } from '~/api';
import { TAllPermissions } from '~/api/permissions/permissions.types';
// todo use icons from ui kit
import { SquareCrossIcon, SquareTickIcon } from '~/assets/icons';
import { Accordion } from '~/components/Accordion';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { EntrySection } from '~/components/EntryScreen';
import { TParams } from '~/router/router.types';
import { formatDate } from '~/utils/formatters/formatDate';

import { ConfirmsSkeleton } from '../ConfirmsSkeleton';

import { getConfirmationText, mapTypeToPermission } from './Confirms.helpers';
import {
  StyledConfirmList,
  StyledConfirmListWrapper,
  StyledConfirmSubTitle,
  StyledConfirmTitle,
  StyledConfirmTitleWrapper,
} from './Confirms.styled';
import { TConfirm, TConfirmsData, THandleCheckbox } from './Confirms.types';

export const Confirms = ({
  data = {},
  type,
  onConfirm,
  isLoading,
}: TConfirmsData) => {
  const { data: permissions } = useGetPermissionsQuery();

  const { id } = useParams<TParams>();
  const [currentConfirm, setCurrentConfirm] = useState('');
  const preparedData = Object.entries(data)?.map(([, value]) => value);

  const handleCheckbox = ({ event, readonly, key }: THandleCheckbox) => {
    if (!readonly) {
      setCurrentConfirm(key);
    }

    event.stopPropagation();
  };

  const onCloseConfirm = () => {
    setCurrentConfirm('');
  };

  const handleConfirm = (key: string) => {
    if (id && key) {
      onConfirm(key, id);
      onCloseConfirm();
    }
  };

  const getPermission = (permission?: string) => {
    if (!permission || !type) {
      return false;
    }

    const key = mapTypeToPermission?.[type];

    return Boolean(permissions?.[key]?.[permission as keyof TAllPermissions]);
  };

  const renderTitle = ({
    title,
    changed,
    value,
    readonly,
    key,
    permission,
  }: TConfirm) => {
    const changedDate = changed ? formatDate(new Date(changed)) : '';
    const confirmText = getConfirmationText(type, key, title);

    const isDisabled = readonly || !getPermission(permission);

    return (
      <StyledConfirmTitleWrapper className="ConfirmTitleWrapper">
        <ActionWithConfirmation
          placement="left"
          openFromExternal
          disabled={isDisabled}
          cancelButtonNameKey="Cancel"
          confirmButtonNameKey="Confirm"
          onConfirm={() => handleConfirm(key)}
          externalOpened={currentConfirm === key}
          closeHandler={onCloseConfirm}
          title={confirmText.title}
          content={confirmText.text}
        >
          <span>
            <Checkbox
              checked={value}
              disabled={isDisabled}
              onClick={(event) =>
                handleCheckbox({ event, readonly: isDisabled, key })
              }
            />
          </span>
        </ActionWithConfirmation>
        <StyledConfirmTitle className="ConfirmTitle">
          {`${title} ${changedDate}`}
        </StyledConfirmTitle>
      </StyledConfirmTitleWrapper>
    );
  };

  if (!preparedData || preparedData?.length === 0) {
    return null;
  }

  if (isLoading) {
    return (
      <EntrySection gap="14px">
        <ConfirmsSkeleton />
      </EntrySection>
    );
  }

  return (
    <EntrySection gap="14px">
      {preparedData.map((confirm) => {
        const { key, checkers } = confirm;

        const isDisabled = checkers.length === 0;
        return (
          <Accordion
            disabled={isDisabled}
            key={key}
            title={renderTitle(confirm)}
            hideArrowForDisabled
          >
            <StyledConfirmListWrapper className="ConfirmList">
              {checkers?.map((item) => {
                const [text, isTrue] = item;

                return (
                  <StyledConfirmList
                    className="ConfirmList"
                    key={text as string}
                  >
                    {isTrue ? <SquareTickIcon /> : <SquareCrossIcon />}
                    <StyledConfirmSubTitle className="ConfirmSubTitle">
                      {text}
                    </StyledConfirmSubTitle>
                  </StyledConfirmList>
                );
              })}
            </StyledConfirmListWrapper>
          </Accordion>
        );
      })}
    </EntrySection>
  );
};
