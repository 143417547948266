import { FC } from 'react';
import { KitTheme, SuspendIcon } from 'react-ui-kit-exante';

import { ERequestsStatuses } from '~/pages/Requests/Requests.constants';

import { EDepositStatuses } from '../api/deposits/deposits.types';
import { EWithdrawalsStatuses } from '../api/withdrawals/withdrawals.types';
import {
  CheckCircleIcon,
  CheckSquareOffsetIcon,
  CloudCheckIcon,
  CrossCircleIcon,
  PlusCircleIcon,
} from '../assets/icons';

export const colorsMap = (
  theme: KitTheme,
): Record<
  | 'withdrawal'
  | 'withdrawalTable'
  | 'deposit'
  | 'depositsTable'
  | 'requestsTable'
  | 'request',
  Record<string, string>
> => {
  return {
    withdrawal: {
      [EWithdrawalsStatuses.Active]: theme?.color?.typo?.active,
      [EWithdrawalsStatuses.OnHold]: theme?.color?.typo?.warning,
      [EWithdrawalsStatuses.Rejected]: theme?.color?.typo?.radical,
      [EWithdrawalsStatuses.Executed]: theme?.color?.typo?.action,
      [EWithdrawalsStatuses.Old]: theme?.color?.bg?.promo,
    },
    withdrawalTable: {
      [EWithdrawalsStatuses.Active]: theme?.color?.bg?.active,
      [EWithdrawalsStatuses.OnHold]: theme?.color?.bg?.radical,
      [EWithdrawalsStatuses.Rejected]: theme?.color?.bg?.warning,
      [EWithdrawalsStatuses.Executed]: theme?.color?.bg?.source,
      [EWithdrawalsStatuses.Old]: theme?.color?.bg?.primary,
    },
    deposit: {
      [EDepositStatuses.Active]: theme?.color?.typo?.active,
      [EDepositStatuses.OnHold]: theme?.color?.typo?.warning,
      [EDepositStatuses.FundsRejected]: theme?.color?.typo?.radical,
      [EDepositStatuses.BookedInBO]: theme?.color?.typo?.action,
      [EDepositStatuses.New]: theme?.color?.typo?.primary,
    },
    depositsTable: {
      [EDepositStatuses.Active]: theme?.color?.bg?.active,
      [EDepositStatuses.OnHold]: theme?.color?.bg?.radical,
      [EDepositStatuses.FundsRejected]: theme?.color?.bg?.warning,
      [EDepositStatuses.BookedInBO]: theme?.color?.bg?.source,
      [EDepositStatuses.New]: '',
    },
    requestsTable: {
      [ERequestsStatuses.Active]: theme?.color?.bg?.active,
      [ERequestsStatuses.Inactive]: theme?.color?.bg?.radical,
      [ERequestsStatuses.Solved]: theme?.color?.bg?.source,
      [ERequestsStatuses.Report]: theme?.color?.bg?.radical,
      [ERequestsStatuses.ReportedToFIU]: theme?.color?.bg?.source,
      [ERequestsStatuses.SARCommittee]: theme?.color?.bg?.source,
      [ERequestsStatuses.InitialReview]: theme?.color?.bg?.active,
    },
    request: {
      [ERequestsStatuses.Active]: theme?.color?.typo?.active,
      [ERequestsStatuses.Inactive]: theme?.color?.typo?.warning,
      [ERequestsStatuses.Solved]: theme?.color?.typo?.action,
      [ERequestsStatuses.Report]: theme?.color?.typo?.warning,
      [ERequestsStatuses.ReportedToFIU]: theme?.color?.typo?.action,
      [ERequestsStatuses.SARCommittee]: theme?.color?.typo?.action,
      [ERequestsStatuses.InitialReview]: theme?.color?.typo?.active,
    },
  };
};

export const mapIcons: Record<
  'withdrawal' | 'deposit' | 'request',
  Record<string, FC>
> = {
  withdrawal: {
    [EWithdrawalsStatuses.Old]: PlusCircleIcon,
    [EWithdrawalsStatuses.OnHold]: CheckSquareOffsetIcon,
    [EWithdrawalsStatuses.Executed]: CheckCircleIcon,
    [EWithdrawalsStatuses.Rejected]: CrossCircleIcon,
    [EWithdrawalsStatuses.Active]: CloudCheckIcon,
  },
  deposit: {
    [EDepositStatuses.New]: PlusCircleIcon,
    [EDepositStatuses.FundsRejected]: CrossCircleIcon,
    [EDepositStatuses.BookedInBO]: CloudCheckIcon,

    // todo replace icons
    [EDepositStatuses.Active]: CloudCheckIcon,
    [EDepositStatuses.OnHold]: SuspendIcon,
  },
  request: {
    [ERequestsStatuses.Active]: CloudCheckIcon,
    [ERequestsStatuses.Inactive]: CrossCircleIcon,
    [ERequestsStatuses.Solved]: CheckCircleIcon,
    [ERequestsStatuses.Report]: CrossCircleIcon,
    [ERequestsStatuses.ReportedToFIU]: CheckCircleIcon,
    [ERequestsStatuses.SARCommittee]: CheckCircleIcon,
    [ERequestsStatuses.InitialReview]: CloudCheckIcon,
  },
};
