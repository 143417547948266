import { ClickAwayListener } from '@mui/material';
import cn from 'classnames';
import { useState } from 'react';

import CircleCheck from '../../../Icons/components/CircleCheck';
import Download from '../../../Icons/components/Download';
import Info from '../../../Icons/components/Info';
import Upload from '../../../Icons/components/Upload';
import { DefaultThemeProvider, useTheme } from '../../../theme';
import { IconButton } from '../../Button/IconButton';
import { Loader } from '../../Loader';
import { Tooltip } from '../../Tooltip';

import { DeleteConfirmationTooltip } from './DeleteConfirmationTooltip';
import {
  Actions,
  Body,
  DragAndDropDownloadIcon,
  DragAndDropWrapper,
  ErrorMessageStyled,
  Head,
  LoaderStyled,
  RootStyled,
  RowContainer,
  RowContainerLeft,
  RowContainerRight,
  RowCustomHeader,
  StateContainer,
  StateContainerLeft,
  StateContainerRight,
  StateFileName,
  StateFileSize,
  StateIcon,
  Text,
  UploadButton,
} from './FileUpload.styled';
import { Skeleton } from './Skeleton';
import {
  MEGABYTE,
  TEST_ID_FILEUPLOAD_ICON_DELETE,
  TEST_ID_FILEUPLOAD_ICON_DOWNLOAD,
  TEST_ID_FILEUPLOAD_ICON_ERROR,
  TEST_ID_FILEUPLOAD_ICON_SUCCESS,
  TEST_ID_FILEUPLOAD_INPUT,
  TEST_ID_FILEUPLOAD_STATE_CONTAINER,
  translatorFallback,
} from './constants';
import { formatBytes } from './helpers';
import type { TFile, TFileUploadProps } from './types';
import { DeleteStatus, UploadStatus } from './types';
import useFileUpload from './useFileUpload';

export const FileUpload = ({
  accept,
  buttonLabel = 'Upload',
  className,
  customHeader,
  deleteConfirmationLabel = 'Are you sure to delete this file?',
  disabled,
  dndLabel = 'Drag-n-drop',
  error,
  errorMaxFileSizeExceedMessage,
  filesInitial,
  info,
  loading = false,
  maxFileSize = 10 * MEGABYTE,
  message,
  onDelete,
  onUpload,
  withDeleteConfirmation = false,
  showDragAndDrop = true,
  showFileSize = true,
  showUploadIconButtonOnly = false,
  tooltip,
  translator = translatorFallback,
  uploadErrorMessage,
  onDownload,
  viewMode,
  isMultiple = true,
}: TFileUploadProps) => {
  const theme = useTheme();

  const {
    dragEvents,
    externalOpened,
    filesToUpload,
    handleDelete,
    handleUpload,
    setExternalOpened,
    uploadError,
  } = useFileUpload({
    accept,
    disabled,
    errorMaxFileSizeExceedMessage,
    filesInitial,
    maxFileSize,
    onDelete,
    onUpload,
    withDeleteConfirmation,
    uploadErrorMessage,
    translator,
    isMultiple,
  });
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const handleTooltipClick = () => setIsTooltipOpened((isOpened) => !isOpened);
  const handleTooltipClose = () => setIsTooltipOpened(false);

  const onConfirmDeleteFile = (id: string | undefined, name: string) =>
    handleDelete(id, name);

  const getCustomDeleteButton = (file: TFile) => {
    if (withDeleteConfirmation) {
      return (
        <DeleteConfirmationTooltip
          disabled={disabled}
          externalOpened={externalOpened === file.id}
          onConfirm={() => onConfirmDeleteFile(file.id, file.name)}
          placement="bottom"
          title={deleteConfirmationLabel}
        >
          <IconButton
            className="IconButton"
            data-test-id={TEST_ID_FILEUPLOAD_ICON_DELETE}
            iconColor="secondary"
            iconName="DeleteIcon"
            iconSize={18}
            onClick={() => setExternalOpened(file.id)}
          />
        </DeleteConfirmationTooltip>
      );
    }

    return (
      <IconButton
        className="IconButton"
        data-test-id={TEST_ID_FILEUPLOAD_ICON_DELETE}
        disabled={disabled}
        iconColor="secondary"
        iconName="DeleteIcon"
        iconSize={18}
        onClick={() => handleDelete(file.id, file.name)}
      />
    );
  };

  if (loading) {
    return <Skeleton info={info} tooltip={tooltip} />;
  }

  return (
    <DefaultThemeProvider>
      <RootStyled
        {...dragEvents}
        className={cn(className, 'Root')}
        hasFile={filesToUpload.length > 0}
        error={error}
      >
        {!viewMode && (
          <Head className="Head">
            {customHeader && (
              <RowCustomHeader className="RowCustomHeader">
                {customHeader}
              </RowCustomHeader>
            )}
            <RowContainer className="RowContainer" fullWidth={!customHeader}>
              <RowContainerLeft className="RowContainerLeft">
                <UploadButton
                  className="UploadButton"
                  color="secondary"
                  component="label"
                  showUploadIconButtonOnly={showUploadIconButtonOnly}
                  startIcon={<Upload alt="upload" size={18} />}
                  disabled={disabled}
                >
                  {showUploadIconButtonOnly ? '' : buttonLabel}
                  <input
                    accept={accept}
                    hidden
                    id="input-file-upload"
                    multiple={isMultiple}
                    onChange={handleUpload}
                    type="file"
                    data-test-id={TEST_ID_FILEUPLOAD_INPUT}
                    disabled={disabled}
                  />
                </UploadButton>
                {showDragAndDrop && (
                  <DragAndDropWrapper className="DragAndDropWrapper">
                    <Text>or</Text>
                    <DragAndDropDownloadIcon className="DragAndDropDownloadIcon">
                      <Download alt="download" size={18} disabled={disabled} />
                      <Text>{dndLabel}</Text>
                    </DragAndDropDownloadIcon>
                  </DragAndDropWrapper>
                )}
              </RowContainerLeft>
              {(info || tooltip) && (
                <RowContainerRight className="RowContainerRight">
                  {info}
                  {tooltip && (
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span>
                        <Tooltip
                          title={tooltip}
                          open={isTooltipOpened}
                          onClick={handleTooltipClick}
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <Info alt="info" size={18} />
                        </Tooltip>
                      </span>
                    </ClickAwayListener>
                  )}
                </RowContainerRight>
              )}
            </RowContainer>
          </Head>
        )}

        {uploadError && (
          <Body className="Body">
            <StateContainer
              className="StateContainerNotice"
              status={UploadStatus.ERROR}
            >
              <StateContainerLeft className="StateContainerLeft">
                <StateIcon className="StateIcon">
                  <Info
                    size={18}
                    alt="info"
                    applyStates={false}
                    color={theme?.color?.icon?.radical}
                  />
                </StateIcon>
                <StateFileName className="StateFileName">
                  {uploadError}
                </StateFileName>
              </StateContainerLeft>
            </StateContainer>
          </Body>
        )}

        {!!filesToUpload.length && (
          <Body className="Body">
            {filesToUpload.map((file) => (
              <StateContainer
                className={`StateContainer StateContainer-${file.uploadStatus}`}
                key={file.name}
                viewMode={viewMode}
                status={file.uploadStatus}
                data-test-id={TEST_ID_FILEUPLOAD_STATE_CONTAINER}
              >
                <StateContainerLeft className="StateContainerLeft">
                  {!viewMode && (
                    <StateIcon className="StateIcon">
                      {file.uploadStatus === UploadStatus.ERROR && (
                        <Info
                          size={18}
                          alt="info"
                          applyStates={false}
                          color={theme?.color?.icon?.radical}
                          data-test-id={TEST_ID_FILEUPLOAD_ICON_ERROR}
                        />
                      )}
                      {file.uploadStatus === UploadStatus.PENDING && (
                        <LoaderStyled className="Loader" />
                      )}
                      {file.uploadStatus === UploadStatus.SUCCESS && (
                        <CircleCheck
                          size={18}
                          alt="circle check"
                          applyStates={false}
                          color={theme?.color?.icon?.source}
                          data-test-id={TEST_ID_FILEUPLOAD_ICON_SUCCESS}
                        />
                      )}
                    </StateIcon>
                  )}
                  <StateFileName className="StateFileName">
                    {file.name}
                  </StateFileName>
                  {showFileSize && (
                    <StateFileSize className="StateFileSize">
                      {formatBytes(file.size)}
                    </StateFileSize>
                  )}
                </StateContainerLeft>
                {(file.removable ||
                  file.uploadStatus === UploadStatus.ERROR) && (
                  <StateContainerRight className="StateContainerRight">
                    {file.deleteStatus === DeleteStatus.PENDING ? (
                      <Loader />
                    ) : (
                      <Actions>
                        {onDownload && (
                          <IconButton
                            className="IconButton"
                            data-test-id={TEST_ID_FILEUPLOAD_ICON_DOWNLOAD}
                            iconColor="secondary"
                            iconName="DownloadIcon"
                            iconSize={18}
                            onClick={() => file?.id && onDownload(file.id)}
                          />
                        )}
                        {!viewMode && getCustomDeleteButton(file)}
                      </Actions>
                    )}
                  </StateContainerRight>
                )}
              </StateContainer>
            ))}
          </Body>
        )}
      </RootStyled>
      {message && (
        <ErrorMessageStyled error={error}>{message}</ErrorMessageStyled>
      )}
    </DefaultThemeProvider>
  );
};

FileUpload.displayName = 'FileUpload';
