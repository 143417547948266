import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Select } from 'react-ui-kit-exante';

import { useGetBookmarksQuery } from '~/api';
import { ROOT_PATH } from '~/router';

import { StyledHeader } from './Header.styled';

// this header only for use app outside run-ui (in run-ui container similar logic)
export const Header = () => {
  const { data: bookmarks } = useGetBookmarksQuery();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchParamBookmark = searchParams.get('bookmark');
  const navigate = useNavigate();
  const [selectedBookmark, setSelectedBookmark] = useState(searchParamBookmark);

  useEffect(() => {
    if (!searchParamBookmark) {
      setSelectedBookmark(null);
    }
  }, [searchParamBookmark]);

  const currentTablePage = location.pathname
    .replace(ROOT_PATH, '')
    .replaceAll('/', '');

  const currentPageBookmarks = bookmarks?.filter(
    (bookmark) => bookmark.bookmarkTab === currentTablePage,
  );

  const currentPageBookmarksOptions =
    currentPageBookmarks?.map((i) => ({
      label: i.text,
      value: i.innerId,
    })) || [];

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedBookmark(e.target.value);

    const goToUrl = () => {
      const link = currentPageBookmarks?.find(
        ({ innerId }) => String(innerId) === String(e.target.value),
      )?.linkTo;

      if (link) {
        navigate(`${location.pathname}${link}`);
      }
    };

    const bookmark = new CustomEvent('onChangeBookmark', {
      detail: {
        onBookmarkChange: goToUrl,
      },
    });

    window.dispatchEvent(bookmark);
  };

  return (
    <StyledHeader className="Header">
      {currentPageBookmarksOptions.length > 0 && (
        <Select
          sx={{ width: 200 }}
          options={currentPageBookmarksOptions}
          onChange={onChange}
          value={selectedBookmark || ''}
          placeholder="Select bookmark"
          label="Select bookmark"
        />
      )}
    </StyledHeader>
  );
};
