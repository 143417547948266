import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { StatusInfo } from 'components/StatusInfo';
import { Confirms } from 'modules/confirms/components/Confirms';
import {
  useGetWithdrawalQuery,
  useLazyGetWithdrawalSecurityQuery,
  usePostWithdrawalConfirmMutation,
} from '~/api';
import { EWithdrawalsStatuses } from '~/api/withdrawals/withdrawals.types';
import {
  EntryColumn,
  EntrySection,
  EntryPage,
  EntryScreenWrapper,
} from '~/components/EntryScreen';
import { Comments } from '~/modules/comments/components/Comments';
import {
  POLLING_ENABLE_WR_STATUSES,
  WITHDRAWAL_TYPE_SECURITY,
} from '~/pages/WithdrawalEntry/WithdrawalEntry.constants';
import { ClientDocuments } from '~/pages/WithdrawalEntry/components/ClientDocuments';
import { TParams } from '~/router/router.types';

import { FilesUploader } from './components/FilesUploader';
import { WithdrawalForm } from './components/WithdrawalForm';
import { WithdrawalFormProvider } from './components/WithdrawalForm/context';
import { WithdrawalHeader } from './components/WithdrawalHeader';
import { WithdrawalSecurityForm } from './components/WithdrawalSecurityForm/WithdrawalSecurityForm';
import { WithdrawalSecurityFormProvider } from './components/WithdrawalSecurityForm/context/WithdrawalSecurityFormContext';

export const WithdrawalEntry = () => {
  const { id: withdrawalId } = useParams<TParams>();
  const [currentStatus, setCurrentStatus] =
    useState<EWithdrawalsStatuses | null>(null);

  const {
    data: withdrawal,
    isLoading,
    isFetching,
  } = useGetWithdrawalQuery(
    {
      id: String(withdrawalId),
    },
    {
      pollingInterval:
        currentStatus && POLLING_ENABLE_WR_STATUSES.includes(currentStatus)
          ? 10_000
          : 0,
    },
  );

  const isSecurity = withdrawal?.type === WITHDRAWAL_TYPE_SECURITY;

  const [getWithdrawalSecurity, withdrawalSecurityState] =
    useLazyGetWithdrawalSecurityQuery();

  const [postConfirm, state] = usePostWithdrawalConfirmMutation();

  useEffect(() => {
    const status = withdrawal?.status;

    if (status) {
      setCurrentStatus(status);
    }

    if (isSecurity) {
      getWithdrawalSecurity({ id: String(withdrawalId) });
    }
  }, [withdrawal]);

  if (isLoading || !withdrawal) {
    return (
      <EntryScreenWrapper>
        <Loader isCentered size={32} />
      </EntryScreenWrapper>
    );
  }

  const {
    status,
    responsible_title: responsibleTitle,
    stages,
    id,
  } = withdrawal;

  const onConfirm = (key: string, entityId: string) => {
    postConfirm({ id: entityId, confirm: key });
  };

  return (
    <EntryScreenWrapper>
      <WithdrawalHeader withdrawal={withdrawal} />
      <EntryPage>
        <EntryColumn>
          <EntrySection>
            <StatusInfo
              entityKey="withdrawal"
              status={status}
              responsibleTitle={responsibleTitle}
            />
          </EntrySection>
          <Confirms
            data={stages}
            type="withdrawal"
            isLoading={state.isLoading}
            onConfirm={onConfirm}
          />
          <ClientDocuments />
          <EntrySection title="Uploaded documents">
            <FilesUploader
              withdrawal={withdrawal}
              isLoading={isLoading || isFetching}
            />
          </EntrySection>
          {isSecurity && withdrawalSecurityState.data && (
            <WithdrawalSecurityFormProvider
              withdrawalSecurity={withdrawalSecurityState.data}
            >
              <WithdrawalSecurityForm />
            </WithdrawalSecurityFormProvider>
          )}
          <EntrySection title="Comments">
            <Comments
              entityId={id}
              entityType="withdrawals"
              needRefetch={isFetching}
            />
          </EntrySection>
        </EntryColumn>
        <WithdrawalFormProvider withdrawal={withdrawal}>
          <WithdrawalForm withdrawal={withdrawal} />
        </WithdrawalFormProvider>
      </EntryPage>
    </EntryScreenWrapper>
  );
};
