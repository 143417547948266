import { FC, SyntheticEvent, useContext, useMemo } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { EWithdrawalsStatuses } from '~/api/withdrawals/withdrawals.types';
import {
  EntryColumn,
  EntrySection,
  EntrySubSection,
} from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';

import { RowFormView, TWithdrawalForm } from './TWithdrawalForm.types';
import { COMPLY_RADAR_WITHDRAWAL_DETAILS_FIELDS } from './WithdrawalForm.constants';
import {
  getInfoWithdrawalDetailsFields,
  getWithdrawalDetailsFields,
} from './WithdrawalForm.helpers';
import { StyledActionsRow } from './WithdrawalForm.styled';
import { EditableWithdrawalForm } from './components/EditableWithdrawalForm';
import { WithdrawalFormContext } from './context';

export const WithdrawalForm: FC<TWithdrawalForm> = ({ withdrawal }) => {
  const {
    isEdit,
    setIsEdit,
    formNotEdited,
    resetForm,
    isCanChangeAmount,
    isCanChange,
  } = useContext(WithdrawalFormContext);

  const onToggleEdit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsEdit((prevState) => !prevState);
  };

  const {
    status,
    username,
    account_owner: accountOwner,
    ip,
    ip_country: ipCountry,
    crm_link: crmLink,
    account_owner_crm_link: accountOwnerCrmLink,
  } = withdrawal;
  const prepareIpCountry = ip && ipCountry ? `${ip} ${ipCountry}` : '–';
  const isEditDisabled =
    !isCanChange ||
    status === EWithdrawalsStatuses.Rejected ||
    status === EWithdrawalsStatuses.Executed;

  const editDisabledState = useMemo(() => {
    if (isCanChangeAmount) {
      return false;
    }
    return isEditDisabled;
  }, [isCanChangeAmount, isEditDisabled]);

  const tooltipMessage = useMemo(() => {
    if (status === EWithdrawalsStatuses.Rejected) {
      return "Cannot change withdrawal in 'Rejected' state";
    }
    if (status === EWithdrawalsStatuses.Executed) {
      return "Cannot change withdrawal in 'Executed' state";
    }
    if (!isCanChange) {
      return 'Not enough permissions for withdrawal edit';
    }
    return '';
  }, [isCanChange]);

  const onCancel = () => {
    resetForm();
    setIsEdit(!isEdit);
  };

  return (
    <EntryColumn>
      <EntrySection
        title="Info"
        action={
          <StyledActionsRow className="ActionsRow">
            {isEdit ? (
              <>
                <Tooltip
                  title={
                    formNotEdited
                      ? 'For save withdrawal please edit some field'
                      : ''
                  }
                >
                  <IconButton
                    title="Save withdrawal data"
                    label="Save"
                    type="submit"
                    iconName="SaveIcon"
                    iconColor="action"
                    iconSize={24}
                    disabled={formNotEdited}
                  />
                </Tooltip>
                <IconButton
                  title="Close form editing"
                  type="button"
                  label="Cancel"
                  iconName="CloseIcon"
                  iconSize={24}
                  onClick={onCancel}
                />
              </>
            ) : (
              <Tooltip title={tooltipMessage}>
                <IconButton
                  title="Open form editing"
                  type="button"
                  iconName="EditIcon"
                  iconColor="action"
                  iconSize={24}
                  onClick={onToggleEdit}
                  disabled={editDisabledState}
                />
              </Tooltip>
            )}
          </StyledActionsRow>
        }
      >
        <EntrySubSection title="Client Details">
          {getInfoWithdrawalDetailsFields({
            username,
            accountOwner,
            crmLink,
            accountOwnerCrmLink,
          }).map((field) => {
            return (
              <ValueRow
                key={field.key}
                label={field.label}
                value={
                  !field.node ? withdrawal?.[field.key as RowFormView] : null
                }
                valueNode={field.node}
              />
            );
          })}
        </EntrySubSection>
        <EntrySubSection title="ComplyRadar">
          {COMPLY_RADAR_WITHDRAWAL_DETAILS_FIELDS.map((field) => {
            return (
              <ValueRow
                key={field.key}
                label={field.label}
                value={withdrawal?.[field.key as RowFormView]}
                withCopyButton
              />
            );
          })}
        </EntrySubSection>
        <EntrySubSection title="Withdrawal Details">
          {getWithdrawalDetailsFields(prepareIpCountry).map(
            ({ key, label, node, formatter }) => {
              const value = withdrawal?.[key as RowFormView];
              const formatValue = formatter
                ? formatter(value as unknown as Date)
                : value;

              return (
                <ValueRow
                  key={key + label}
                  label={label}
                  valueNode={node}
                  value={!node ? formatValue : null}
                />
              );
            },
          )}
        </EntrySubSection>
        <EntrySubSection>
          <EditableWithdrawalForm withdrawal={withdrawal} isEditMode={isEdit} />
        </EntrySubSection>
      </EntrySection>
    </EntryColumn>
  );
};
