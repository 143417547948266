import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Loader, Panel } from 'react-ui-kit-exante';

import {
  useDeleteMonitoringFileMutation,
  useEditRequestMutation,
  useGetAdminChoicesQuery,
  useGetRecordStateQuery,
  useGetRecordTypesQuery,
  useGetRequestQuery,
  useLazyDownloadMonitoringFileQuery,
  useLazyGetApplicationLegalEntitiesQuery,
  useLazyGetMonitoringTagsQuery,
  useSendRequestMutation,
  useUploadMonitoringFilesMutation,
} from '~/api';
import { PATHS } from '~/router';
import { TParams } from '~/router/router.types';

import { RequestEntry } from './RequestEntry';
import { RequestType } from './RequestEntry.constants';
import {
  prepareOptions,
  priorityAndSourceOptions,
} from './RequestEntry.helpers';
import { StyledRequestEntryErrorPage } from './RequestEntry.styled';

export const RequestEntryContainer: FC = () => {
  const navigate = useNavigate();
  const { id: requestId } = useParams<TParams>();
  const {
    data: request,
    isLoading,
    isFetching,
    error,
  } = useGetRequestQuery({
    id: requestId as string,
  });
  const { data: recordTypes } = useGetRecordTypesQuery();
  const { data: receivedFrom } = useGetAdminChoicesQuery(
    'groups__name[]=onboarding',
  );
  const { data: assigned, isLoading: isLoadingAdminChoices } =
    useGetAdminChoicesQuery('groups__name[]=sales_qa&groups__name[]=manager');
  const [fetchApplicationLegalEntities, stateLegalEntities] =
    useLazyGetApplicationLegalEntitiesQuery();
  const [onEditRequest, editState] = useEditRequestMutation();
  const [onUploadFiles, uploadState] = useUploadMonitoringFilesMutation();
  const [onDeleteFile, deleteState] = useDeleteMonitoringFileMutation();
  const [onDownloadFile] = useLazyDownloadMonitoringFileQuery();
  const [onSendRequest, stateSend] = useSendRequestMutation();
  const { data: states } = useGetRecordStateQuery();
  const [fetchMonitoringTags, stateMonitoringTags] =
    useLazyGetMonitoringTagsQuery();

  const isAdditionalFields =
    request?.type.id === RequestType.SarSTR ||
    request?.type.id === RequestType.InvestigativeCase;

  useEffect(() => {
    if (isAdditionalFields) {
      fetchMonitoringTags();
    }
  }, [request]);

  const receivedFromOptions = prepareOptions(receivedFrom);

  const optionsForm = {
    recordTypes,
    receivedFrom: receivedFromOptions,
    assigned: isAdditionalFields
      ? receivedFromOptions
      : prepareOptions(assigned),
    legalEntities:
      stateLegalEntities?.data?.legal_entities?.map((item) => ({
        value: item,
        label: item,
      })) || [],
    localMRLO: receivedFromOptions,
    priority: priorityAndSourceOptions(
      stateMonitoringTags.data,
      'monitoring / priority',
    ),
    source: priorityAndSourceOptions(
      stateMonitoringTags.data,
      'monitoring / source',
    ),
  };

  useEffect(() => {
    if (request?.application) {
      fetchApplicationLegalEntities({ id: request?.application });
    }
  }, [request]);

  const isSendLoading = stateSend.isLoading;
  const isEditLoading = editState.isLoading;
  const isProcessing =
    editState.isLoading ||
    uploadState.isLoading ||
    deleteState.isLoading ||
    stateSend.isLoading ||
    isFetching;

  const isDataLoaders =
    isEmpty(request) ||
    isLoading ||
    isLoadingAdminChoices ||
    stateMonitoringTags.isLoading;

  const isMainLoader =
    !uploadState.isUninitialized || !deleteState.isUninitialized
      ? isFetching || isDataLoaders || isProcessing
      : isDataLoaders;

  const getFile = async (id: string) => {
    const { data: dataUrl } = await onDownloadFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    uploadState.reset();
    deleteState.reset();
  }, [request]);

  if (error) {
    return (
      <Panel
        title="Error"
        action={
          <IconButton
            type="button"
            iconSize={32}
            iconName="CloseIcon"
            iconColor="promo"
            onClick={() => navigate(PATHS.MONITORING)}
            className="EntryScreenHeaderClose"
          />
        }
      >
        <StyledRequestEntryErrorPage className="RequestEntryErrorPage">
          {error as string}
        </StyledRequestEntryErrorPage>
      </Panel>
    );
  }

  if (isMainLoader) {
    return <Loader isCentered size="l" />;
  }

  return (
    <RequestEntry
      states={states}
      request={request}
      isEditLoading={isEditLoading}
      isSendLoading={isSendLoading}
      isProcessing={isProcessing}
      optionsForm={optionsForm}
      onDeleteFile={onDeleteFile}
      onUploadFiles={onUploadFiles}
      onDownloadFile={getFile}
      onSendRequest={onSendRequest}
      onEditRequest={onEditRequest}
    />
  );
};
