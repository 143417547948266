import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import qs from 'qs';

import { ACCESS_TOKEN_COOKIE_KEY } from '~/constants/session';

import { removeEmptyStrings } from '../removeEmptyStrings';

import { getAPIProtocol, getBaseCrmUrl } from './base';
import { TAccessToken, TErrorWithResponse } from './types';

export function getDefaultHeaders(accessToken = '') {
  const baseHeaders: { 'Content-Type': string } = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);
      return {
        ...baseHeaders,
        Authorization: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}

export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = removeEmptyStrings(params);

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}

export const isAxiosError = (err: unknown): err is AxiosError => {
  return Boolean(get(err, 'isAxiosError'));
};

export const getAccessTokenFromCookies = () =>
  Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

function isErrorWithResponse(error: unknown): error is TErrorWithResponse {
  return (error as TErrorWithResponse).response !== undefined;
}

export function check403Error(error: unknown) {
  return (
    isErrorWithResponse(error) &&
    error?.response &&
    error?.response.status === 403
  );
}

export function getAPIHost() {
  const APIhost = getBaseCrmUrl();
  return `${getAPIProtocol(APIhost)}://${APIhost}`;
}

// here we can handle any errors from server
export const handleError = (err: unknown): string => {
  if (isAxiosError(err)) {
    if (err.response?.data?.message) {
      return err.response?.data?.message;
    }
    if (err.response?.data?.detail) {
      return err.response?.data?.detail;
    }
    if (err.response?.data) {
      return JSON.stringify(err.response?.data);
    }
  }

  return String(err);
};
