import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Panel, Tabs, Tab, TabPanel } from 'react-ui-kit-exante';

import { useGetClientsAreaPermissionsQuery } from '~/api';
import { getTableId } from '~/utils/table';

import { PAGE_SIZE, PERMISSIONS_COLUMNS } from './PermissionsTables.constants';
import { StyledPermissionsTable } from './PermissionsTables.styled';

export const PermissionsTables = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { data, isLoading } = useGetClientsAreaPermissionsQuery();

  return (
    <Panel
      title="About Permissions"
      action={
        <IconButton
          title="Close"
          iconSize={24}
          iconColor="promo"
          iconName="CloseIcon"
          className="ClosePermissions"
          onClick={() => navigate(-1)}
          data-test-id="about-permissions__button--close"
        />
      }
    >
      <Tabs
        value={activeTab}
        onChange={(e, value) => {
          setActiveTab(value);
        }}
      >
        <Tab label="General permissions" />
        <Tab label="Special tags permissions" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <StyledPermissionsTable
          isFlexLayout
          isPinnedHeader
          disableSortBy
          hasPagination
          saveColumnOrder
          defaultSortBy={[]}
          isHiddenColumnSelect
          pageSize={PAGE_SIZE}
          isLoading={isLoading}
          data={data?.permissions || []}
          columns={PERMISSIONS_COLUMNS}
          className="GeneralPermissionsTable"
          tableId={getTableId('general-permissions')}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <StyledPermissionsTable
          isFlexLayout
          isPinnedHeader
          disableSortBy
          hasPagination
          saveColumnOrder
          defaultSortBy={[]}
          isHiddenColumnSelect
          pageSize={PAGE_SIZE}
          isLoading={isLoading}
          data={data?.tags || []}
          columns={PERMISSIONS_COLUMNS}
          className="SpecialTagsPermissionsTable"
          tableId={getTableId('special-tags-permissions')}
        />
      </TabPanel>
    </Panel>
  );
};
